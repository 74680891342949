import Meta from '@/components/Meta';
import Breadcrumb from '@/components/ui/Breadcrumb';
import Container from '@/components/ui/Container';
import LayoutMain from '@/layouts/LayoutMain';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Head from 'next/head';

const Custom404 = () => {
  const router = useRouter();

  useEffect(() => {
    if (router.asPath.startsWith('/careers/')) {
      window.location.replace('/careers/');
    }

    if (
      router.asPath.startsWith('/news/') ||
      router.asPath.startsWith('/thenews/')
    ) {
      window.location.replace('/news/');
    }

    if (
      router.asPath.startsWith('/testimonials/') ||
      router.asPath.startsWith('/testimony-locations/')
    ) {
      window.location.replace('/testimonials/');
    }

    if (router.asPath.startsWith('/serviced-offices/')) {
      window.location.replace('/serviced-offices/');
    }

    if (router.asPath.startsWith('/meeting-rooms/')) {
      window.location.replace('/meeting-rooms/');
    }

    if (router.asPath.startsWith('/meet-the-team/')) {
      window.location.replace('/meet-the-team/');
    }

    if (router.asPath.startsWith('/case-studies/')) {
      window.location.replace('/case-studies/');
    }
  }, [router]);

  return (
    <>
      <Meta title="404 - Page Not Found" />

      <Head>
        <meta name="robots" content="noindex,nofollow" />
      </Head>

      <Breadcrumb />

      <Container size="xl">
        <h1 className="mt-8">404 - Page Not Found</h1>
        <p className="text-center">
          We&rsquo;re sorry, but the requested page cannot be found.
        </p>
      </Container>
    </>
  );
};

Custom404.Layout = LayoutMain;
export default Custom404;
